import ax from 'axios'

ax.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': first_page_token_param
};

export const axios = ax

export default {
    install(app, options) {
        app.provide('$axios', axios);
    }
}

