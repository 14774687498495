<template>
  <svg width="35px" height="35px" version="1.1" viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m265.12 254.96c2.4258 0 4.8438-0.92188 6.6953-2.7734l5.9023-5.9023c3.6992-3.6992 3.6992-9.6992 0-13.395-3.6992-3.6992-9.6953-3.6992-13.395 0l-5.9023 5.9023c-3.6992 3.6953-3.6992 9.6953 0 13.395 1.8516 1.8516 4.2773 2.7734 6.6992 2.7734z"/>
      <path d="m252.34 258.28c-3.6992-3.6992-9.6953-3.6992-13.395 0l-21.973 21.973c-3.6992 3.6953-3.6992 9.6953 0 13.395 1.8516 1.8516 4.2734 2.7734 6.6953 2.7734 2.4258 0 4.8438-0.92188 6.6953-2.7734l21.973-21.973c3.7031-3.7031 3.7031-9.6992 0.003906-13.395z"/>
      <path d="m274.25 262.64-17.309 17.309c-3.6992 3.6953-3.6992 9.6953 0 13.395 1.8516 1.8516 4.2734 2.7734 6.6953 2.7734 2.4258 0 4.8438-0.92188 6.6953-2.7734l17.309-17.309c3.6992-3.6992 3.6992-9.6992 0-13.395-3.6953-3.6992-9.6914-3.6992-13.391 0z"/>
      <path d="m427.5 317.34c0.60938 0 1.2344-0.0625 1.8594-0.18359 16.422-3.2734 32.719-3.3828 48.434-0.32812 5.168 1.0078 10.109-2.3594 11.105-7.4883 1-5.1367-2.3555-10.109-7.4883-11.109-18.148-3.5234-36.902-3.4102-55.746 0.34766-5.1289 1.0234-8.457 6.0078-7.4414 11.137 0.89453 4.5078 4.8477 7.625 9.2773 7.625z"/>
      <path d="m552.23 464.77-41.785-17.434-0.074219 0.17969v-0.28516h-18.941v71.039h-75.766v-71.039h-18.941v0.29688l-0.007812-0.019531-40.723 17.266c-5.668 2.4062-10.68 5.6445-14.918 9.5234l-0.003906-273.73h-146.81v274.68h145.82c-1.5469 1.4922-2.9961 3.0703-4.3203 4.7344h-146.24v-284.16h307.83v72.461c-12.086-9.5039-27.273-15.227-43.805-15.227-39.168 0-71.039 31.867-71.039 71.039v14.234c-7.2773 5-11.84 13.355-11.84 22.465 0 9.2695 4.7227 17.758 12.223 22.723 3.7227 35.617 34.277 63.707 70.652 63.707 36.379 0 66.938-28.09 70.652-63.707 7.5-4.9648 12.223-13.453 12.223-22.723 0-9.1133-4.5625-17.465-11.84-22.465v-14.234c0-11.996-3.0156-23.297-8.2891-33.219l0.007812-114h-345.71v322.04h156.19c-0.71094 3.25-1.0938 6.6055-1.0938 10.043v66.129h18.941v-66.129c0-11.766 7.0078-21.766 18.762-26.742l4.9219-2.082v38.133h-0.003906v56.828h18.941v-37.887h132.6v37.887h18.941v-56.828h-0.003906v-38.594l6.1523 2.5703c11.309 4.7188 17.531 14.145 17.531 26.551v66.301h18.941v-66.301c0.007813-19.953-10.906-36.418-29.176-44.039zm-98.684-192.76c28.723 0 52.094 23.371 52.094 52.094v9.1914c-33.219-12.816-67.547-12.613-104.19 0.56641v-9.7656c0-28.715 23.371-52.086 52.094-52.086zm58.398 96.617-6.1797 2.1719-0.13672 6.5547c-0.61719 28.09-23.977 50.938-52.078 50.938-28.102 0-51.461-22.852-52.078-50.941l-0.14062-6.5508-6.1758-2.1719c-3.3086-1.168-5.5352-4.3086-5.5352-7.8203 0-3.2344 1.9336-6.082 4.8164-7.4336l1.3516 3.0859c41.367-18.105 79.027-18.172 115.11-0.19922l1.5117-3.0352c3.0391 1.293 5.0781 4.2422 5.0781 7.582-0.011719 3.5117-2.2383 6.6484-5.5469 7.8203zm-298.74 87.68v-236.8h108.92v113.66h-28.414v18.941h28.414v104.19zm174.05 61.969v-46.164l9.4727-4.0195v50.184zm132.59 0h-9.4727v-50.441l9.4727 3.9492z"/>
      <path d="m453.55 456.71c-5.2188 0-9.4727 4.2539-9.4727 9.4727s4.2539 9.4727 9.4727 9.4727 9.4727-4.2539 9.4727-9.4727c0-5.2227-4.2539-9.4727-9.4727-9.4727z"/>
      <path d="m453.55 485.12c-5.2188 0-9.4727 4.2578-9.4727 9.4766s4.2539 9.4688 9.4727 9.4688 9.4727-4.2461 9.4727-9.4688c0-5.2188-4.2539-9.4766-9.4727-9.4766z"/>
      <path d="m473.68 219.45h18.945v-18.871h-146.81v246.66h18.941v-227.72h108.92z"/>
    </g>
  </svg>
</template>
<script>

export default {
    name: "IconRepairWindow",
}
</script>
<style scoped>
.v-icon__component {
  width: 35px;
  height: 35px;
}
</style>
