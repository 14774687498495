import { createVuetify } from 'vuetify';
import colors from 'vuetify/lib/util/colors';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import * as labsComponents from 'vuetify/labs/components';
import customSVGs from "../components/custom_icons/customSvgs"

// Translations provided by Vuetify
import { ru } from 'vuetify/locale';

// Misc
// import { loadFonts } from '@/plugins/webfontloader';

// Styles
import 'vuetify/styles';

// await loadFonts();

/**
 * Vuetify Components
 *
 * @see {@link https://vuetifyjs.com/en/features/treeshaking/}
 */


let vuetifyConfig = {
  // Global configuration
  // https://vuetifyjs.com/en/features/global-configuration/
  defaults: {
    VCheckbox: {
      color: 'primary',
    },
    VTextField: {
      density: "compact",
      variant: "underlined"
    }
  },
  // Icon Fonts
  // https://vuetifyjs.com/en/features/icon-fonts/
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      custom: customSVGs,
    },
  },
  // Internationalization (i18n)
  // https://vuetifyjs.com/en/features/internationalization/#internationalization-i18n
  locale: {
    locale: 'ru',
    fallback: 'ru',
    messages: { ru },
  },
  // Theme
  // https://vuetifyjs.com/en/features/theme/
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: colors.blue.darken2,
        }
      },
    },
  },
};

// if (import.meta.env.DEV) {
if (false) {
  // Disable treeshaking for DEV mode.
  vuetifyConfig = {
    components: { components, labsComponents },
    directives,
    ...vuetifyConfig,
  };
}

export default createVuetify(vuetifyConfig);
