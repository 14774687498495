<template>
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
	<path style="fill:#EDEDEE;" d="M407.146,0c0,9.304-7.57,16.873-16.873,16.873S373.401,9.304,373.401,0H340.01
		c0,9.304-7.569,16.873-16.872,16.873c-9.303,0-16.874-7.569-16.874-16.873h-33.391c0,9.304-7.57,16.873-16.873,16.873
		c-9.303,0-16.873-7.569-16.873-16.873h-33.391c0,9.304-7.57,16.873-16.873,16.873c-9.303,0-16.873-7.569-16.873-16.873H138.6
		c0,9.304-7.57,16.873-16.873,16.873S104.855,9.304,104.855,0H71.464v512h33.391c0-9.304,7.57-16.873,16.873-16.873
		S138.6,502.696,138.6,512h33.391c0-9.304,7.569-16.873,16.872-16.873c9.303,0,16.873,7.569,16.873,16.873h33.391
		c0-9.304,7.57-16.873,16.873-16.873s16.873,7.569,16.873,16.873h33.391c0-9.304,7.57-16.873,16.873-16.873
		s16.871,7.569,16.871,16.873h33.391c0-9.304,7.57-16.873,16.873-16.873c9.303,0,16.873,7.569,16.873,16.873h33.391V0H407.146z"/>
	<path style="fill:#DDDCDF;" d="M407.146,0c0,9.304-7.57,16.873-16.873,16.873S373.401,9.304,373.401,0H340.01
		c0,9.304-7.569,16.873-16.872,16.873c-9.303,0-16.874-7.569-16.874-16.873h-33.391c0,9.304-7.57,16.873-16.873,16.873v478.255
		c9.303,0,16.873,7.569,16.873,16.873h33.391c0-9.304,7.57-16.873,16.873-16.873c9.303,0,16.871,7.569,16.871,16.873H373.4
		c0-9.304,7.57-16.873,16.873-16.873s16.873,7.569,16.873,16.873h33.391V0H407.146z"/>
	<g>
		<rect x="149.894" y="102.378" style="fill:#7F7F83;" width="134.278" height="33.391"/>
		<rect x="317.741" y="102.378" style="fill:#7F7F83;" width="44.364" height="33.391"/>
		<rect x="149.894" y="176.796" style="fill:#7F7F83;" width="134.278" height="33.391"/>
		<rect x="317.741" y="176.796" style="fill:#7F7F83;" width="44.364" height="33.391"/>
		<rect x="149.894" y="251.214" style="fill:#7F7F83;" width="134.278" height="33.391"/>
		<rect x="317.741" y="251.214" style="fill:#7F7F83;" width="44.364" height="33.391"/>
	</g>
	<rect x="222.431" y="371.979" style="fill:#E3744E;" width="139.676" height="33.391"/>
	</svg>
</template>
<script>

export default {
    name: "IconInvoice",
}
</script>
