import IconPlasticWindow from './IconPlasticWindow.vue'
import IconInvoice from './IconInvoice.vue'
import IconCreditCard from './IconCreditCard.vue'
import IconRepairWindow from './IconRepairWindow.vue'
import IconShieldUserPhone from './IconShieldUserPhone.vue'

import { h } from "vue";

const customSvgNameToComponent = {
  IconPlasticWindow,
  IconInvoice,
  IconCreditCard,
  IconRepairWindow,
  IconShieldUserPhone
};

const customSVGs = {
  component: (props) => h(customSvgNameToComponent[props.icon]),
};

export default customSVGs