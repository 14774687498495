<template>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 426.71 426.71" style="enable-background:new 0 0 426.71 426.71;" xml:space="preserve">
  <path style="fill:#1185E0;" d="M210.743,426.71c-2.09,0-4.18-0.522-6.269-1.045l-20.376-8.882
    c-82.024-36.049-135.314-117.029-135.314-206.89V96c0-5.747,3.135-10.971,8.359-13.584L203.951,1.959
    c4.702-2.612,10.449-2.612,15.151,0l150.465,80.457c5.224,2.612,8.359,7.837,8.359,13.584v111.804
    c0,90.906-54.335,172.408-137.927,207.935l-22.988,9.927C214.922,426.188,212.833,426.71,210.743,426.71z M80.131,105.404v104.49
    c0,76.8,45.976,146.808,116.506,178.155l14.106,6.269l16.718-7.314l0,0c72.62-30.824,119.118-100.833,119.118-179.2v-102.4
    L211.265,33.306L80.131,105.404z"/>
  <path style="fill:#4DCFE0;" d="M189.845,272.065c-4.18,0-8.882-2.09-12.016-5.747l-39.706-46.498
    c-5.747-6.792-4.702-16.718,1.567-21.943c6.792-5.747,16.718-4.702,21.943,1.567l29.78,35.004l80.457-65.829
    c6.792-5.224,16.718-4.702,21.943,2.09c5.224,6.792,4.702,16.718-2.09,21.943l-91.951,75.755
    C196.637,271.02,193.502,272.065,189.845,272.065z"/>
  </svg>
</template>
<script>
export default {
  name: "IconShieldUserPhone",
}
</script>
