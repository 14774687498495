<template>
  <svg width="752pt" height="752pt" version="1.1" viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m577.27 186.57h-402.54c-6.543 0-11.84 5.2969-11.84 11.84v355.18c0 6.5391 5.2969 11.84 11.84 11.84h402.54c6.5391 0 11.84-5.3008 11.84-11.84v-355.18c0-6.543-5.3008-11.84-11.84-11.84zm-11.84 355.18h-378.86v-331.5h378.86z"/>
      <path d="m216.17 518.07h118.39c3.2695 0 5.9219-2.6523 5.9219-5.918v-272.31c0-3.2695-2.6523-5.9219-5.9219-5.9219h-118.39c-3.2695 0-5.918 2.6523-5.918 5.9219v272.31c0 3.2656 2.6484 5.918 5.918 5.918zm5.918-272.31h106.56v260.47h-106.56z"/>
      <path d="m358.24 529.91h17.758c0 3.2695 2.6523 5.9219 5.9219 5.9219h23.68c3.2656 0 5.918-2.6523 5.918-5.9219h82.879c0 3.2695 2.6484 5.9219 5.918 5.9219h23.68c3.2656 0 5.918-2.6523 5.918-5.9219h17.762c3.2656 0 5.918-2.6523 5.918-5.918v-17.762c3.2695 0 5.9219-2.6484 5.9219-5.918v-23.68c0-3.2656-2.6523-5.918-5.9219-5.918v-189.43c3.2695 0 5.9219-2.6523 5.9219-5.918v-23.68c0-3.2695-2.6523-5.9219-5.9219-5.9219v-17.758c0-3.2695-2.6523-5.9219-5.918-5.9219h-189.43c-3.2695 0-5.918 2.6523-5.918 5.9219v295.99c0 3.2656 2.6484 5.918 5.918 5.918zm5.918-17.758v-278.23h177.59v284.15h-177.59z"/>
      <path d="m393.76 494.4h118.39c3.2656 0 5.918-2.6523 5.918-5.9219v-224.95c0-3.2695-2.6523-5.9219-5.918-5.9219h-118.39c-3.2695 0-5.9219 2.6523-5.9219 5.9219v224.95c0 3.2695 2.6523 5.9219 5.9219 5.9219zm5.918-224.95h106.55v213.11h-106.55z"/>
    </g>
  </svg>
</template>
<script>

export default {
    name: "IconPlasticWindow",
}
</script>
<style scoped>
.v-icon__component {
  width: 35px;
  height: 35px;
}
</style>
